import { DefaultButton, DetailsList, DetailsListLayoutMode, Icon, MarqueeSelection, Selection, SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthHeaders } from '~/src/identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function UnmanagedList({
  rows
}) {
  const selection = React.useMemo(() => new Selection(), []);
  return _jsx(MarqueeSelection, {
    selection: selection,
    children: _jsx(DetailsList, {
      items: rows,
      columns: columns,
      compact: true,
      layoutMode: DetailsListLayoutMode.justified,
      selectionMode: SelectionMode.multiple,
      selection: selection
    })
  });
}
const columns = [{
  key: 'sku',
  fieldName: 'sku',
  name: 'SKU',
  minWidth: 100,
  maxWidth: 150,
  isResizable: true
}, {
  key: 'name',
  name: 'Name',
  onRender: item => item.rawZoho?.name ?? item.rawSquarespace?.descriptor ?? '-',
  minWidth: 100,
  isResizable: true
}, {
  key: 'zoho',
  onRender: item => item.rawZoho ? _jsx(Icon, {
    iconName: "checkMark"
  }) : _jsx(Icon, {
    iconName: "clear"
  }),
  name: 'Zoho',
  minWidth: 50
}, {
  key: 'squarespace',
  onRender: item => item.rawSquarespace ? _jsx(Icon, {
    iconName: "checkMark"
  }) : _jsx(Icon, {
    iconName: "clear"
  }),
  name: 'Squarespace',
  minWidth: 100
}, {
  key: 'actions',
  onRender: item => _jsx(ActionCell, {
    item: item
  }),
  name: '',
  minWidth: 80
}];

function ActionCell({
  item
}) {
  const authHeaders = useAuthHeaders();
  const queryClient = useQueryClient();
  const addManagedMutation = useMutation({
    mutationFn: () => fetch('/.netlify/functions/managedStock-add', {
      method: 'POST',
      headers: { ...authHeaders
      },
      body: JSON.stringify({
        skus: [item.sku]
      })
    }),
    onSuccess: () => queryClient.invalidateQueries('managedStock-list')
  });
  const handleAddManaged = React.useCallback(() => addManagedMutation.mutate(), [addManagedMutation]);
  const menuProps = React.useMemo(() => ({
    items: [{
      key: 'manage',
      name: 'Add to managed items',
      onClick: handleAddManaged
    }]
  }), [handleAddManaged]);
  return _jsx(DefaultButton, {
    menuProps: menuProps,
    children: "More"
  });
}