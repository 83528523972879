function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { Global } from '@emotion/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { App } from './App';
import { PrivateRoute, useIdentity } from './identity';
import { Login } from './Login';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";

var _ref = process.env.NODE_ENV === "production" ? {
  name: "1o1js1e",
  styles: "body{margin:0;}"
} : {
  name: "mksxi0-Root",
  styles: "body{margin:0;};label:Root;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlJvb3QudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVdjIiwiZmlsZSI6IlJvb3QudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgR2xvYmFsIH0gZnJvbSAnQGVtb3Rpb24vcmVhY3QnO1xuaW1wb3J0IHsgTmF2aWdhdGUsIFJvdXRlLCBSb3V0ZXMgfSBmcm9tICdyZWFjdC1yb3V0ZXItZG9tJztcbmltcG9ydCB7IEFwcCB9IGZyb20gJy4vQXBwJztcbmltcG9ydCB7IFByaXZhdGVSb3V0ZSwgdXNlSWRlbnRpdHkgfSBmcm9tICcuL2lkZW50aXR5JztcbmltcG9ydCB7IExvZ2luIH0gZnJvbSAnLi9Mb2dpbic7XG5cbmV4cG9ydCBmdW5jdGlvbiBSb290KCkge1xuICBjb25zdCB1c2VyID0gdXNlSWRlbnRpdHkoKTtcblxuICByZXR1cm4gKFxuICAgIDw+XG4gICAgICA8R2xvYmFsIHN0eWxlcz17eyBib2R5OiB7IG1hcmdpbjogMCB9IH19IC8+XG4gICAgICA8Um91dGVzPlxuICAgICAgICA8Um91dGVcbiAgICAgICAgICBwYXRoPVwiL1wiXG4gICAgICAgICAgZWxlbWVudD17dXNlciA/IDxOYXZpZ2F0ZSB0bz17eyBwYXRobmFtZTogJy9hcHAnIH19IC8+IDogPExvZ2luIC8+fVxuICAgICAgICAvPlxuXG4gICAgICAgIDxQcml2YXRlUm91dGUgcGF0aD1cIi9hcHAvKlwiIGVsZW1lbnQ9ezxBcHAgLz59IC8+XG5cbiAgICAgICAgey8qIDxSb3V0ZSBwYXRoPVwiKlwiIGVsZW1lbnQ9ezxkaXY+NDA0PC9kaXY+fS8+ICovfVxuICAgICAgPC9Sb3V0ZXM+XG4gICAgPC8+XG4gICk7XG59XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};

export function Root() {
  const user = useIdentity();
  return _jsxs(_Fragment, {
    children: [_jsx(Global, {
      styles: _ref
    }), _jsxs(Routes, {
      children: [_jsx(Route, {
        path: "/",
        element: user ? _jsx(Navigate, {
          to: {
            pathname: '/app'
          }
        }) : _jsx(Login, {})
      }), _jsx(PrivateRoute, {
        path: "/app/*",
        element: _jsx(App, {})
      })]
    })]
  });
}