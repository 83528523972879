function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { DefaultButton } from '@fluentui/react';
import { useLogin } from '../identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var _ref = process.env.NODE_ENV === "production" ? {
  name: "xnobmq",
  styles: "display:flex;flex-direction:column;height:100vh;justify-content:center;align-items:center"
} : {
  name: "1et304p-Login",
  styles: "display:flex;flex-direction:column;height:100vh;justify-content:center;align-items:center;label:Login;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZGV4LnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRTSIsImZpbGUiOiJpbmRleC50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBEZWZhdWx0QnV0dG9uIH0gZnJvbSAnQGZsdWVudHVpL3JlYWN0JztcbmltcG9ydCB7IHVzZUxvZ2luIH0gZnJvbSAnLi4vaWRlbnRpdHknO1xuXG5leHBvcnQgZnVuY3Rpb24gTG9naW4oKSB7XG4gIGNvbnN0IGxvZ2luID0gdXNlTG9naW4oKTtcblxuICByZXR1cm4gKFxuICAgIDxkaXZcbiAgICAgIGNzcz17e1xuICAgICAgICBkaXNwbGF5OiAnZmxleCcsXG4gICAgICAgIGZsZXhEaXJlY3Rpb246ICdjb2x1bW4nLFxuICAgICAgICBoZWlnaHQ6ICcxMDB2aCcsXG4gICAgICAgIGp1c3RpZnlDb250ZW50OiAnY2VudGVyJyxcbiAgICAgICAgYWxpZ25JdGVtczogJ2NlbnRlcicsXG4gICAgICB9fVxuICAgID5cbiAgICAgIDxoMT5Nb3JlIFRoYW4gV2lsZGZsb3dlcnMgTWFuYWdlcjwvaDE+XG4gICAgICA8RGVmYXVsdEJ1dHRvbiBvbkNsaWNrPXsoKSA9PiBsb2dpbigpfT5Mb2cgaW48L0RlZmF1bHRCdXR0b24+XG4gICAgPC9kaXY+XG4gICk7XG59XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};

export function Login() {
  const login = useLogin();
  return _jsxs("div", {
    css: _ref,
    children: [_jsx("h1", {
      children: "More Than Wildflowers Manager"
    }), _jsx(DefaultButton, {
      onClick: () => login(),
      children: "Log in"
    })]
  });
}