import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useAuthHeaders } from '~/src/identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function Redirect() {
  const [searchParams] = useSearchParams();
  const authHeaders = useAuthHeaders();
  const mutation = useMutation(async code => {
    if (code === null) throw new Error('OAuth code missing');
    const result = await fetch(`/.netlify/functions/zoho-auth-redirect?${new URLSearchParams({
      code
    })}`, {
      headers: { ...authHeaders
      }
    }).then(r => r.json());
    console.log(result);
    return result;
  });
  useEffect(() => mutation.mutate(searchParams.get('code')), []);
  const {
    data,
    error,
    isLoading
  } = mutation;
  return _jsx("div", {
    children: _jsx("pre", {
      children: JSON.stringify({
        isLoading,
        error,
        data
      }, null, 2)
    })
  });
}