import { DetailsList, DetailsListLayoutMode, MarqueeSelection, PrimaryButton, Selection, SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthHeaders } from '~/src/identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ManagedList({
  rows
}) {
  const selection = React.useMemo(() => new Selection(), []);
  return _jsx(MarqueeSelection, {
    selection: selection,
    children: _jsx(DetailsList, {
      items: rows,
      columns: columns,
      compact: true,
      layoutMode: DetailsListLayoutMode.justified,
      selectionMode: SelectionMode.multiple,
      selection: selection
    })
  });
}
const columns = [{
  key: 'sku',
  fieldName: 'sku',
  name: 'SKU',
  minWidth: 100,
  maxWidth: 150,
  isResizable: true
}, {
  key: 'name',
  name: 'Name',
  onRender: item => item.rawZoho?.name ?? item.rawSquarespace?.descriptor ?? '-',
  minWidth: 100,
  isResizable: true
}, {
  key: 'zoho',
  onRender: item => item.zoho ?? '-',
  name: 'Zoho',
  minWidth: 50
}, {
  key: 'adjustments',
  onRender: item => item.adjustments ?? '-',
  name: 'Adjusted',
  minWidth: 50
}, {
  key: 'squarespaceExpected',
  onRender: item => item.squarespaceExpected ?? '-',
  name: 'SQ Expected',
  minWidth: 50
}, {
  key: 'squarespaceActual',
  onRender: item => item.squarespaceActual ?? '-',
  name: 'SQ Actual',
  minWidth: 50
}, {
  key: 'actions',
  onRender: item => _jsx(ActionCell, {
    item: item
  }),
  name: '',
  minWidth: 210
}];

function ActionCell({
  item
}) {
  const authHeaders = useAuthHeaders();
  const queryClient = useQueryClient();
  const removeManagedMutation = useMutation({
    mutationFn: () => fetch('/.netlify/functions/managedStock-remove', {
      method: 'POST',
      headers: { ...authHeaders
      },
      body: JSON.stringify({
        skus: [item.sku]
      })
    }),
    onSuccess: () => queryClient.invalidateQueries('managedStock-list')
  });
  const handleRemoveManaged = React.useCallback(() => removeManagedMutation.mutate(), [removeManagedMutation]);
  const setSqMutation = useMutation({
    mutationFn: () => fetch('/.netlify/functions/sq-inventory-update', {
      method: 'POST',
      headers: { ...authHeaders
      },
      body: JSON.stringify({
        variantId: item.rawSquarespace.variantId,
        quantity: item.squarespaceExpected
      })
    }),
    onSuccess: () => queryClient.invalidateQueries('sq.inventory')
  });
  const handleSetSq = React.useCallback(() => setSqMutation.mutate(), [setSqMutation]);
  const needsFixing = item.squarespaceActual !== item.squarespaceExpected;
  const menuProps = React.useMemo(() => ({
    items: [{
      key: 'unmanage',
      name: 'Remove from managed items',
      onClick: handleRemoveManaged
    }]
  }), []);
  return _jsx(PrimaryButton, {
    split: true,
    menuProps: menuProps,
    primary: true,
    primaryDisabled: !needsFixing,
    onClick: handleSetSq,
    children: "Update Squarespace"
  });
}