import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useIdentity } from './IdentityProvider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function PrivateRoute({
  element,
  ...rest
}) {
  let user = useIdentity();
  return _jsx(Route, { ...rest,
    element: user ? element : _jsx(Navigate, {
      to: {
        pathname: '/'
      }
    })
  });
}