import * as React from 'react';
import { Route, Routes } from 'react-router';
import { Page } from '~/src/UI/Page';
import { Redirect } from './Redirect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ZohoSettings() {
  return _jsxs(Page.Page, {
    children: [_jsx(Page.Heading, {
      children: "Zoho"
    }), _jsxs(Routes, {
      children: [_jsx(Route, {
        path: "/",
        element: _jsx(StartZohoAuthButton, {})
      }), _jsx(Route, {
        path: "/redirect",
        element: _jsx(Redirect, {})
      })]
    })]
  });
}

function StartZohoAuthButton() {
  const handleStartAuth = React.useCallback(() => {
    const scopes = ['ZohoBooks.settings.READ,ZohoBooks.banking.Create,ZohoBooks.banking.READ'];
    const params = new URLSearchParams({
      scope: scopes.join(','),
      client_id: process.env.ZOHO_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.APP_ROOT}/app/settings/zoho/redirect`,
      access_type: 'offline' // get refresh token as well as access token

    });
    window.location.href = `https://accounts.zoho.com.au/oauth/v2/auth?${params}`;
  }, []);
  return _jsx("div", {
    children: _jsx("button", {
      onClick: handleStartAuth,
      children: "Start ZOHO AUTH"
    })
  });
}