import { DetailsList, DetailsListLayoutMode, MarqueeSelection, Selection, SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useAuthHeaders } from '~/src/identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function SquarespaceStock() {
  const authHeaders = useAuthHeaders();
  const {
    isLoading,
    error,
    data
  } = useQuery('sq.stock', () => fetch('/.netlify/functions/sq-inventory-list', {
    headers: { ...authHeaders
    }
  }).then(r => r.json()));
  const selection = React.useMemo(() => new Selection(), []);
  const items = data?.inventory ?? [];
  return _jsx(MarqueeSelection, {
    selection: selection,
    children: _jsx(DetailsList, {
      items: items,
      columns: columns,
      compact: true,
      layoutMode: DetailsListLayoutMode.justified,
      selectionMode: SelectionMode.multiple,
      selection: selection
    })
  });
}
const columns = [{
  key: 'sku',
  fieldName: 'sku',
  name: 'SKU',
  minWidth: 100,
  maxWidth: 150,
  isResizable: true
}, {
  key: 'name',
  fieldName: 'descriptor',
  name: 'Name',
  minWidth: 200,
  isResizable: true
}, {
  key: 'Quantity',
  fieldName: 'quantity',
  name: 'Quantity',
  minWidth: 100,
  isResizable: true
}];