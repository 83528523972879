function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { Checkbox, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useAuthHeaders } from '~/src/identity';
import { Page } from '~/src/UI/Page';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var _ref = process.env.NODE_ENV === "production" ? {
  name: "18uqayh",
  styles: "margin-bottom:16px"
} : {
  name: "lqebjm-StripeImport",
  styles: "margin-bottom:16px;label:StripeImport;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZGV4LnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUE4QlEiLCJmaWxlIjoiaW5kZXgudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgQ2hlY2tib3gsIFByaW1hcnlCdXR0b24gfSBmcm9tICdAZmx1ZW50dWkvcmVhY3QnO1xuaW1wb3J0IHsgdXNlQm9vbGVhbiB9IGZyb20gJ0BmbHVlbnR1aS9yZWFjdC1ob29rcyc7XG5pbXBvcnQgKiBhcyBSZWFjdCBmcm9tICdyZWFjdCc7XG5pbXBvcnQgeyB1c2VNdXRhdGlvbiB9IGZyb20gJ3JlYWN0LXF1ZXJ5JztcbmltcG9ydCB7IFJvdXRlLCBSb3V0ZXMgfSBmcm9tICdyZWFjdC1yb3V0ZXInO1xuaW1wb3J0IHsgcmVuZXdab2hvVG9rZW4gfSBmcm9tICd+L25ldGxpZnkvem9oby9yZW5ld1Rva2VuJztcbmltcG9ydCB7IHVzZUF1dGhIZWFkZXJzIH0gZnJvbSAnfi9zcmMvaWRlbnRpdHknO1xuaW1wb3J0IHsgUGFnZSB9IGZyb20gJ34vc3JjL1VJL1BhZ2UnO1xuXG5leHBvcnQgZnVuY3Rpb24gU3RyaXBlSW1wb3J0KCkge1xuICBjb25zdCBbZHJ5UnVuLCB7IHRvZ2dsZTogdG9nZ2xlRHJ5UnVuIH1dID0gdXNlQm9vbGVhbih0cnVlKTtcblxuICBjb25zdCBhdXRoSGVhZGVycyA9IHVzZUF1dGhIZWFkZXJzKCk7XG5cbiAgY29uc3QgbXV0YXRpb24gPSB1c2VNdXRhdGlvbihhc3luYyAoKSA9PiB7XG4gICAgY29uc3QgcmVzdWx0ID0gYXdhaXQgZmV0Y2goXG4gICAgICBgLy5uZXRsaWZ5L2Z1bmN0aW9ucy9pbXBvcnQtc3RyaXBlLXRyYW5zYWN0aW9ucz9kcnlSdW49JHtkcnlSdW59YCxcbiAgICAgIHsgaGVhZGVyczogeyAuLi5hdXRoSGVhZGVycyB9IH0sXG4gICAgKS50aGVuKChyKSA9PiByLmpzb24oKSk7XG5cbiAgICByZXR1cm4gcmVzdWx0O1xuICB9KTtcblxuICByZXR1cm4gKFxuICAgIDxQYWdlLlBhZ2U+XG4gICAgICA8UGFnZS5IZWFkaW5nPlN0cmlwZSBJbXBvcnQ8L1BhZ2UuSGVhZGluZz5cbiAgICAgIDxDaGVja2JveFxuICAgICAgICBsYWJlbD1cIkRyeSBydW5cIlxuICAgICAgICBjaGVja2VkPXtkcnlSdW59XG4gICAgICAgIG9uQ2hhbmdlPXt0b2dnbGVEcnlSdW59XG4gICAgICAgIGNzcz17eyBtYXJnaW5Cb3R0b206IDE2IH19XG4gICAgICAvPlxuICAgICAgPFByaW1hcnlCdXR0b25cbiAgICAgICAgb25DbGljaz17KCkgPT4gbXV0YXRpb24ubXV0YXRlKCl9XG4gICAgICAgIGRpc2FibGVkPXttdXRhdGlvbi5pc0xvYWRpbmd9XG4gICAgICA+XG4gICAgICAgIEltcG9ydFxuICAgICAgPC9QcmltYXJ5QnV0dG9uPlxuICAgICAgPHByZT57SlNPTi5zdHJpbmdpZnkobXV0YXRpb24uZGF0YSwgbnVsbCwgMil9PC9wcmU+XG4gICAgPC9QYWdlLlBhZ2U+XG4gICk7XG59XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};

export function StripeImport() {
  const [dryRun, {
    toggle: toggleDryRun
  }] = useBoolean(true);
  const authHeaders = useAuthHeaders();
  const mutation = useMutation(async () => {
    const result = await fetch(`/.netlify/functions/import-stripe-transactions?dryRun=${dryRun}`, {
      headers: { ...authHeaders
      }
    }).then(r => r.json());
    return result;
  });
  return _jsxs(Page.Page, {
    children: [_jsx(Page.Heading, {
      children: "Stripe Import"
    }), _jsx(Checkbox, {
      label: "Dry run",
      checked: dryRun,
      onChange: toggleDryRun,
      css: _ref
    }), _jsx(PrimaryButton, {
      onClick: () => mutation.mutate(),
      disabled: mutation.isLoading,
      children: "Import"
    }), _jsx("pre", {
      children: JSON.stringify(mutation.data, null, 2)
    })]
  });
}