function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var _ref = process.env.NODE_ENV === "production" ? {
  name: "1u0qmvp",
  styles: "margin:16px"
} : {
  name: "12crv2r-Page",
  styles: "margin:16px;label:Page;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlBhZ2UudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlnQiIsImZpbGUiOiJQYWdlLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCAqIGFzIFJlYWN0IGZyb20gJ3JlYWN0JztcblxuZXhwb3J0IGNvbnN0IFBhZ2UgPSB7XG4gIFBhZ2UoeyBjaGlsZHJlbiB9OiBSZWFjdC5Qcm9wc1dpdGhDaGlsZHJlbjx7fT4pIHtcbiAgICByZXR1cm4gPGRpdiBjc3M9e3sgbWFyZ2luOiAnMTZweCcgfX0+e2NoaWxkcmVufTwvZGl2PjtcbiAgfSxcblxuICBIZWFkaW5nKHsgY2hpbGRyZW4gfTogUmVhY3QuUHJvcHNXaXRoQ2hpbGRyZW48e30+KSB7XG4gICAgcmV0dXJuIDxoMiBjc3M9e3sgbWFyZ2luVG9wOiAwIH19PntjaGlsZHJlbn08L2gyPjtcbiAgfSxcbn07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};

var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1i9vogi",
  styles: "margin-top:0"
} : {
  name: "q5tzda-Heading",
  styles: "margin-top:0;label:Heading;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlBhZ2UudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVFlIiwiZmlsZSI6IlBhZ2UudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0ICogYXMgUmVhY3QgZnJvbSAncmVhY3QnO1xuXG5leHBvcnQgY29uc3QgUGFnZSA9IHtcbiAgUGFnZSh7IGNoaWxkcmVuIH06IFJlYWN0LlByb3BzV2l0aENoaWxkcmVuPHt9Pikge1xuICAgIHJldHVybiA8ZGl2IGNzcz17eyBtYXJnaW46ICcxNnB4JyB9fT57Y2hpbGRyZW59PC9kaXY+O1xuICB9LFxuXG4gIEhlYWRpbmcoeyBjaGlsZHJlbiB9OiBSZWFjdC5Qcm9wc1dpdGhDaGlsZHJlbjx7fT4pIHtcbiAgICByZXR1cm4gPGgyIGNzcz17eyBtYXJnaW5Ub3A6IDAgfX0+e2NoaWxkcmVufTwvaDI+O1xuICB9LFxufTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};

export const Page = {
  Page({
    children
  }) {
    return _jsx("div", {
      css: _ref,
      children: children
    });
  },

  Heading({
    children
  }) {
    return _jsx("h2", {
      css: _ref2,
      children: children
    });
  }

};