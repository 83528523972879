import { initializeIcons, ThemeProvider } from '@fluentui/react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Root } from './Root';
import { IdentityProvider, netlifyIdentityInit } from './identity';
import { theme } from './theme'; // // refresh the user's JWT
// // Note: this method returns a promise.
// netlifyIdentity.refresh().then((jwt)=>console.log(jwt))

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
initializeIcons();
netlifyIdentityInit();
const queryClient = new QueryClient();
render(_jsx(ThemeProvider, {
  theme: theme,
  children: _jsx(IdentityProvider, {
    children: _jsx(BrowserRouter, {
      children: _jsx(QueryClientProvider, {
        client: queryClient,
        children: _jsx(Root, {})
      })
    })
  })
}), document.getElementById('root'));