import { createTheme } from '@fluentui/style-utilities';
export const theme = createTheme({
  palette: {
    themePrimary: '#8b4b6b',
    themeLighterAlt: '#faf6f8',
    themeLighter: '#eddbe4',
    themeLight: '#ddbecd',
    themeTertiary: '#ba87a0',
    themeSecondary: '#9a5c7b',
    themeDarkAlt: '#7e4461',
    themeDark: '#6b3a52',
    themeDarker: '#4f2a3c',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff'
  },
  defaultFontStyle: {// fontFamily: 'Raleway, "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    // fontWeight: 'regular',
  }
});