import { DetailsList, DetailsListLayoutMode, MarqueeSelection, Selection, SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useAuthHeaders } from '~/src/identity';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ZohoStock() {
  const authHeaders = useAuthHeaders();
  const {
    isLoading,
    error,
    data
  } = useQuery('zoho.stock', () => fetch('/.netlify/functions/zoho-inventory-list', {
    headers: { ...authHeaders
    }
  }).then(r => r.json()));
  const selection = React.useMemo(() => new Selection(), []);
  const items = data?.items ?? [];
  return _jsx(MarqueeSelection, {
    selection: selection,
    children: _jsx(DetailsList, {
      items: items,
      columns: columns,
      compact: true,
      layoutMode: DetailsListLayoutMode.justified,
      selectionMode: SelectionMode.multiple,
      selection: selection
    })
  });
}
const columns = [{
  key: 'sku',
  fieldName: 'sku',
  name: 'SKU',
  minWidth: 100
}, {
  key: 'name',
  fieldName: 'name',
  name: 'Name',
  minWidth: 100
}, {
  key: 'stock_on_hand',
  fieldName: 'stock_on_hand',
  name: 'Stock On Hand',
  minWidth: 100
}];